import React, { useState } from "react";
import { connect } from "react-redux";
import RadioOption from "../RadioOption";
import { FormHelperText, TextField } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import { setPackageData } from "src/actions";
export const CategorySelection = ({ data, selected, ...otherProps }) => {
  const {
    id,
    name,
    description,
    addOns,
    selection_type,
    shipping_inside,
    shipping_outside,
  } = data;
  const price = selected.reduce(
    (a, { price, quantity, included }) =>
      included ? a + price * (quantity - 1) : a + price * quantity,
    0
  );
  let deliveryShippingAddress = otherProps.categoryData.find(
    (item) => item.id === 10
  );
  const [collectPerson, setcollectPerson] = useState(
    // "Fernwood Cemetery At 6000 Santa Monica Blvd Los Angeles, CA, 90038"
    "Fernwood, 301 Tennessee Valley Road, Mill Valley, CA 94941"
  );

  let serviceShippingAddress = otherProps.categoryData.find(
    (item) => item.id === 77
  );
  const [collectServicePerson, setcollectServicePerson] = useState(
    "Fernwood, 301 Tennessee Valley Road, Mill Valley, CA 94941"
  );

  return (
    <div className="main-payment-custom">
      <div className="main-payment">
        {" "}
        {/* custome add  */}
        {/* <div className="first-payment"> */}
        <div className="payment-op1">
          <h3>{`${name}`}</h3>
          {selection_type ? (
            <h6
            // className="text-white"
            >
              ${parseFloat(price).toFixed(2)}
            </h6>
          ) : id === 31 || id === 32 ? (
            <h6>
              {serviceShippingAddress && serviceShippingAddress.catId === id
                ? "$" + parseFloat(otherProps.extraMileServicePrice).toFixed(2)
                : "$" + parseFloat(price).toFixed(2)}
            </h6>
          ) : (
            <h6>
              {/* old */}
              {deliveryShippingAddress && deliveryShippingAddress.catId === id
                ? "$" + parseFloat(otherProps.getShippingTotal()).toFixed(2)
                : ""}

                {/* new for user can manuly price change in refund case */}
                {/* {otherProps.refundStatus && deliveryShippingAddress && deliveryShippingAddress.catId === id ? (
                    <input
                      type="number"
                      value={otherProps.shippingAmount}
                      onChange={otherProps.handleShippingChange}
                      min="0"
                      step="0.01"
                      style={{
                        border: '2px solid #169873', // green border
                        borderRadius: '4px', // Rounded corners
                        padding: '8px', // Spacing inside the input
                        fontSize: '16px', // Font size
                        width: '100%', // Full width
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Subtle shadow
                        transition: 'border-color 0.3s', // Smooth border color transition
                      }}
                      onFocus={(e) => e.target.style.borderColor = '#0056b3'} // Darker blue on focus
                      onBlur={(e) => e.target.style.borderColor = '#007BFF'} // Reset border color on blur
                 
                    />
                  ) : (
                    deliveryShippingAddress && deliveryShippingAddress.catId === id ? (
                      "$" + parseFloat(otherProps.getShippingTotal()).toFixed(2)
                    ) : ("")
                  )
                } */}

            </h6>
          )}
        </div>
        {description !== "" && description !== null ? (
          <div className="cat-description">
            <p>{description}</p>
          </div>
        ) : (
          ""
        )}
        {/* </div> */}
        <div
          className="que-option que-option-tow"
          //  style={{backgroundColor:"#FFFFFF",paddingLeft: "20px", marginTop:"0px"}}
        >
          {addOns.map((item, index) => {
            return (
              <>
                <RadioOption
                  key={index}
                  data={item}
                  categoryKey={id}
                  selected={selected.find(({ id }) => item.id === id)}
                  type={selection_type}
                  collectPerson={collectPerson}
                  setErrorMessageForUrn={otherProps.setErrorMessageForUrn}
                  setErrorMessageForAdditionServices={
                    otherProps.setErrorMessageForAdditionServices
                  }
                  setErrorMessageForDelivery={
                    otherProps.setErrorMessageForDelivery
                  }
                  setErrorMessageForDeliveryAddress={
                    otherProps.setErrorMessageForDeliveryAddress
                  }
                  shipping_inside={shipping_inside}
                  shipping_outside={shipping_outside}
                  collectServicePerson={collectServicePerson}
                  setErrorMessageForPeacemaker={
                    otherProps.setErrorMessageForPeacemaker
                  }
                  setErrorMessageForServiceArea={
                    otherProps.setErrorMessageForServiceArea
                  }
                  setErrorMessageForServiceAreaAddress={
                    otherProps.setErrorMessageForServiceAreaAddress
                  }
                  setExtraMileServicePrice={otherProps.setExtraMileServicePrice}

                  refundStatus={otherProps.refundStatus}
                  adjustPayment={otherProps.adjustPayment}

                />
              </>
            );
          })}
        </div>
      </div>

      {selection_type === 0 &&
      id === 2 &&
      otherProps.categoryData.find(
        (item) => item.id === 9 || item.id === 10
      ) ? (
        <div className="delivery_address">
          {deliveryShippingAddress ? (
            <>
              <TextField
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#000",
                  borderRadius: 1,
                  padding: "15px 20px",
                }}
                fullWidth
                name=""
                id="outlined-basic"
                label=""
                hiddenLabel={true}
                variant="standard"
                InputProps={{
                  inputComponent: ({
                    inputRef,
                    onFocus,
                    onBlur,
                    onChange,
                    ...props
                  }) => (
                    <Autocomplete
                      style={{
                        backgroundColor: "#fff",
                        borderColor: "#000",
                        borderRadius: 1,
                      }}
                      {...props}
                      apiKey={"AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"}
                      options={{
                        types: ["geocode", "establishment"],
                        fields: ["ALL"],
                      }}
                      onPlaceSelected={async(place) => {
                        place.address_components.length > 0 &&
                          place.address_components.map((address_component) => {
                            if (
                              address_component.types[0] ===
                              "administrative_area_level_1"
                            ) {
                              deliveryShippingAddress = {
                                ...deliveryShippingAddress,
                                state: address_component.long_name,
                              };
                              otherProps.setPackageData(
                                deliveryShippingAddress
                              );
                              otherProps.setErrorMessageForDeliveryAddress("");
                            }
                          });
                        deliveryShippingAddress = {
                          ...deliveryShippingAddress,
                          address: place.formatted_address,
                        };
                        otherProps.setPackageData(deliveryShippingAddress);
                        otherProps.setErrorMessageForDeliveryAddress("");
                      }}
                      // options={{
                      //   types: ["establishment"],
                      // }}
                      defaultValue={
                        deliveryShippingAddress.address !== undefined &&
                        deliveryShippingAddress.address !== ""
                          ? deliveryShippingAddress.address
                          : ""
                      }
                      onChange={(e) => {
                        if (e.target.value === "") {
                          deliveryShippingAddress = {
                            ...deliveryShippingAddress,
                            address: e.target.value,
                          };
                          otherProps.setPackageData(deliveryShippingAddress);
                          otherProps.setErrorMessageForDeliveryAddress(
                            "Please enter delivery address."
                          );
                        }
                      }}
                    />
                  ),
                }}
                onChange={(e) => {
                  otherProps.setDeliveryAddress(e.target.value);
                }}
                error={otherProps.errorMessageForDeliveryAddress !== ""}
                margin="normal"
                required
              />
              {/* <FormHelperText error margin="normal">
                {otherProps.errorMessageForDeliveryAddress}
              </FormHelperText> */}
            </>
          ) : (
            <TextField
              style={{
                backgroundColor: "#fff",
                borderColor: "#000",
                borderRadius: 1,
                padding: "15px 20px",
              }}
              fullWidth
              hiddenLabel
              name="collect_person_address"
              id="outlined-basic"
              // label="Delivery Address"
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={collectPerson}
              margin="normal"
            />
          )}
        </div>
      ) : (
        ""
      )}

      {selection_type === 0 &&
      id === 32 &&
      otherProps.categoryData.find(
        (item) => item.id === 76 || item.id === 77
      ) ? (
        <div className="main-payment">
          {serviceShippingAddress ? (
            <>
              <span>
                What is the address where your loved one is currently located?
                (extra mile charges may apply){" "}
              </span>
              <TextField
                sx={{
                  bgcolor: "#fff",
                  borderColor: "#000",
                  borderRadius: 1,
                }}
                fullWidth
                name=""
                id="outlined-basic"
                label=""
                hiddenLabel={true}
                variant="standard"
                InputProps={{
                  inputComponent: ({
                    inputRef,
                    onFocus,
                    onBlur,
                    onChange,
                    ...props
                  }) => (
                    <Autocomplete
                      sx={{
                        bgcolor: "#fff",
                        borderColor: "#000",
                        borderRadius: 1,
                      }}
                      {...props}
                      apiKey={"AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"}
                      options={{
                        types: ["geocode", "establishment"],
                        fields: ["ALL"],
                      }}
                      onPlaceSelected={(place) => {
                        place.address_components.length > 0 &&
                          place.address_components.map((address_component) => {
                            if (
                              address_component.types[0] ===
                              "administrative_area_level_1"
                            ) {
                              serviceShippingAddress = {
                                ...serviceShippingAddress,
                                state: address_component.long_name,
                              };
                              otherProps.setPackageData(serviceShippingAddress);
                              otherProps.setErrorMessageForServiceAreaAddress(
                                ""
                              );
                            } else if (
                              address_component.types[0] === "locality"
                            ) {
                              serviceShippingAddress = {
                                ...serviceShippingAddress,
                                city: address_component.long_name,
                              };
                              otherProps.setPackageData(serviceShippingAddress);
                              otherProps.setErrorMessageForServiceAreaAddress(
                                ""
                              );
                            } else if (
                              address_component.types[0] === "postal_code"
                            ) {
                              serviceShippingAddress = {
                                ...serviceShippingAddress,
                                zip: address_component.long_name,
                              };
                              otherProps.setPackageData(serviceShippingAddress);
                              otherProps.setErrorMessageForServiceAreaAddress(
                                ""
                              );
                            } else if (
                              address_component.types[0] === "country"
                            ) {
                              serviceShippingAddress = {
                                ...serviceShippingAddress,
                                country: address_component.long_name,
                              };
                              otherProps.setPackageData(serviceShippingAddress);
                              otherProps.setErrorMessageForServiceAreaAddress(
                                ""
                              );
                            }
                          });
                        serviceShippingAddress = {
                          ...serviceShippingAddress,
                          address: place.formatted_address,
                          lat: place.geometry.location.lat(),
                          long: place.geometry.location.lng(),
                        };
                        otherProps.setPackageData(serviceShippingAddress);
                        otherProps.setErrorMessageForServiceAreaAddress("");
                        otherProps.getExtraMailesCharges(
                          serviceShippingAddress
                        );
                      }}
                      // options={{
                      //   types: ["establishment"],
                      // }}
                      defaultValue={
                        serviceShippingAddress.address !== undefined &&
                        serviceShippingAddress.address !== ""
                          ? serviceShippingAddress.address
                          : ""
                      }
                      onChange={(e) => {
                        if (e.target.value === "") {
                          serviceShippingAddress = {
                            ...serviceShippingAddress,
                            address: e.target.value,
                          };
                          otherProps.setPackageData(serviceShippingAddress);
                          otherProps.setErrorMessageForServiceAreaAddress(
                            "Please enter service area address."
                          );
                          otherProps.getExtraMailesCharges(
                            serviceShippingAddress
                          );
                        }
                      }}
                    />
                  ),
                }}
                // onChange={(e) => {
                //   otherProps.setDeliveryAddress(e.target.value);
                // }}
                error={otherProps.errorMessageForServiceAreaAddress !== ""}
                margin="normal"
                required
              />
            </>
          ) : (
            <>
              <span>
                What is the address where your loved one is currently located?
                (extra mile charges may apply){" "}
              </span>
              <TextField
                sx={{
                  bgcolor: "#fff",
                  borderColor: "#000",
                  borderRadius: 1,
                  // padding: "15px 20px",
                }}
                fullWidth
                hiddenLabel
                name="collect_service_address"
                id="outlined-basic"
                // label="Delivery Address"
                variant="standard"
                InputProps={{
                  readOnly: true,
                }}
                value={collectServicePerson}
                margin="normal"
              />
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = { setPackageData };

export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);
